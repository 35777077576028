
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import { BusinessBranchModel, BusinessBranchSocialLink, SocialLinkType, UpsertSocialLinkModel } from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { FormPage } from '@/mixins'
import Modal from '@/components/actions/Modal.vue'
import { InputSelect, InputText } from '@/components/inputs'
import ServiceOfferedReadDto
  from '../../../../../tradingmate-modules/src/models/api/businesses/branches/ServiceOffered/ServiceOfferedReadDto'

@Component({
  components: {
    Modal,
    InputText,
    InputSelect
  }
})
export default class SocialLinkEditor extends Mixins(FormPage) {
  @Prop({ required: true })
  private readonly branchId!: string;

  @Prop({ required: true })
  private readonly value!: BusinessBranchSocialLink[]

  @Prop({ default: false })
  private readonly vertical!: boolean;

  public socialMediaModalActive = false;

  private upsertSocialLinkModel: UpsertSocialLinkModel = {
    LinkType: SocialLinkType.None,
    Link: ''
  };

  private socialMedias: DropdownListItem[] = [
    { Key: SocialLinkType[1], Label: 'Facebook', Value: SocialLinkType.Facebook },
    { Key: SocialLinkType[2], Label: 'Instagram', Value: SocialLinkType.Instagram },
    { Key: SocialLinkType[3], Label: 'Linkedin', Value: SocialLinkType.Linkedin },
    { Key: SocialLinkType[4], Label: 'Google My Business', Value: SocialLinkType.GoogleMyBusiness },
    { Key: SocialLinkType[5], Label: 'Video', Value: SocialLinkType.Video },
    { Key: SocialLinkType[6], Label: 'Twitter', Value: SocialLinkType.Twitter },
    { Key: SocialLinkType[7], Label: 'TripAdvisor', Value: SocialLinkType.TripAdvisor },
    { Key: SocialLinkType[8], Label: 'Pinterest', Value: SocialLinkType.Pinterest },
    { Key: SocialLinkType[9], Label: 'Etsy', Value: SocialLinkType.Etsy },
    { Key: SocialLinkType[10], Label: 'Website', Value: SocialLinkType.Website }
  ]

  public addSocialMedia (): void {
    this.loading = true

    Services.API.Businesses.AddSocialLink(this.branchId, this.upsertSocialLinkModel)
      .then((returnSocialLink) => {
        const newValue = [...this.value, returnSocialLink]
        this.$emit('input', newValue)
        this.upsertSocialLinkModel = { LinkType: SocialLinkType.None, Link: '' }
        this.socialMediaModalActive = false
      })
      .catch((error) => { this.httpError = error })
      .finally(() => { this.loading = false })
  }

  private updateDebounce: number | undefined = undefined

  UpdateSocialLink (link: BusinessBranchSocialLink): void {
    window.clearTimeout(this.updateDebounce)
    this.updateDebounce = window.setTimeout(() => {
      Services.API.Businesses.UpdateSocialLink(this.branchId, link.SocialLinkId, { LinkType: link.LinkType, Link: link.Link })
    }, 1000)
  }

  deleteSocialLink (link: BusinessBranchSocialLink): void {
    Services.API.Businesses.DeleteSocialLink(this.branchId, link.SocialLinkId)
      .then((returnSocialLink) => {
        const newValue = this.value
        const index = newValue.findIndex((item) => item.LinkType === returnSocialLink.LinkType)
        newValue.splice(index, 1)
        this.$emit('input', newValue)
      })
  }
}
