
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import { BusinessBranchContactMethod, BusinessBranchModel, UpsertBusinessBranchContactMethod } from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { FormPage } from '@/mixins'
import ContactMethodType from 'tradingmate-modules/src/models/api/ContactMethodType'
import InputType from 'tradingmate-modules/src/models/api/forms/InputType'
import Modal from '@/components/actions/Modal.vue'
import { InputSelect, InputText } from '@/components/inputs'

@Component({
  components: {
    Modal,
    InputSelect,
    InputText
  }
})
export default class ContactMethodEditor extends Mixins(FormPage) {
  @Prop({ required: true })
  private readonly branchId!: string;

  @Prop({ required: true })
  private readonly value!: BusinessBranchContactMethod[];

  public modalActive = false;

  private upsertBusinessBranchContactMethod: UpsertBusinessBranchContactMethod = {
    ContactMethodType: ContactMethodType.None,
    Value: ''
  };

  getType (type: ContactMethodType): InputType {
    switch (type) {
      case ContactMethodType.Email:
        return InputType.email
      case ContactMethodType.Landline:
        return InputType.tel
      case ContactMethodType.MobilePhone:
        return InputType.tel
      default:
        return InputType.text
    }
  }

   private valueList: DropdownListItem[] = [
     { Key: ContactMethodType[1], Label: 'Email', Value: ContactMethodType.Email },
     { Key: ContactMethodType[2], Label: 'Mobile Phone', Value: ContactMethodType.MobilePhone },
     { Key: ContactMethodType[3], Label: 'Landline', Value: ContactMethodType.Landline }
   ]

   CreateContactMethod (): void {
     // if (!this.branch) return
     this.loading = true
     Services.API.Businesses.CreateContactMethod(this.branchId, this.upsertBusinessBranchContactMethod)
       .then((returnContact) => {
         this.value.push(returnContact)
         this.upsertBusinessBranchContactMethod = { ContactMethodType: ContactMethodType.None, Value: '' }
         this.modalActive = false
       })
       .catch((error) => { this.httpError = error })
       .finally(() => { this.loading = false })
   }

  private updateDebounce: number | undefined = undefined

  UpdateContactMethod (contact: BusinessBranchContactMethod): void {
    // if (!this.branch) return
    window.clearTimeout(this.updateDebounce)
    this.updateDebounce = window.setTimeout(() => {
      Services.API.Businesses.UpdateContactMethod(this.branchId, contact.ContactMethodId, { ContactMethodType: contact.ContactMethodType, Value: contact.Value })
    }, 1000)
  }

  DeleteContactMethod (contact: BusinessBranchContactMethod): void {
    // if (!this.branch) return
    Services.API.Businesses.DeleteContactMethod(this.branchId, contact.ContactMethodId)
      .then((returnContact) =>
        this.value.splice(
          this.value.findIndex((item) => item.ContactMethodType === returnContact.ContactMethodType),
          1))
  }
}
